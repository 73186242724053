import React from 'react';
import './Common.css';
import { Box, } from '@mui/material';


export default function GuestAccessRejected()  {
    return(
        <Box className="form-body">
            <Box className="form-logo" ></Box>
            <Box className="form-name" >Access Restricted</Box>
            <Box className="form-data" >Looks like you do not have access to this application.<br/>
                As a Guest user, you can only access these applications - <a
                    href="https://uat-developer.msci.com/">Developer Community</a> and <a
                    href="https://qa.dataexplorer.msci.com/">Data Explorer</a>.<br/><br/>
                If you want access to this application, please raise a case with us on <a
                    href="https://support.msci.com">support.msci.com</a>.<br/>
                You may now close this window.
            </Box>
        </Box>
    )

}

